import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Accordion } from 'react-bootstrap';

function Politicas() {
  const [politicas, setPoliticas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPoliticas = async () => {
      try {
        const response = await axios.get('https://pasteleria-api.onrender.com/verPoliticas1');
        setPoliticas(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al recuperar las políticas:', error);
        setLoading(false);
      }
    };
    
    fetchPoliticas();
  }, []);

  return (
    <div className="container fondo-pasteleria">
      <div className="container">
        <h1>Políticas</h1>
        <Accordion>
          {loading ? (
            <p>Cargando...</p>
          ) : politicas.length === 0 ? (
            <p>No hay políticas disponibles.</p>
          ) : (
            politicas.map((politica, index) => (
              <Accordion.Item key={index} eventKey={index.toString()}>
                <Accordion.Header style={{ backgroundColor: '#FF6347', color: '#fff' }}>{politica.nombre}</Accordion.Header>
                <Accordion.Body className="mi-fondo" style={{ backgroundColor: '#FFA07A' }}>
                  {politica.descripcion}
                </Accordion.Body>
              </Accordion.Item>
            ))
          )}
        </Accordion>
      </div>
    </div>
  );
}

export default Politicas;
