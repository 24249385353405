import React, { useState, useEffect } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';

export default function OtroApartado() {
  const [politicas, setPoliticas] = useState([]);
  const [loading, setLoading] = useState(true);

  // Función para cargar las políticas
  const cargarPoliticas = async () => {
    try {
      const response = await axios.get('https://pasteleria-api.onrender.com/verPoliticas1');
      setPoliticas(response.data.map(politica => ({ ...politica, editing: false })));
      setLoading(false);
    } catch (error) {
      console.error('Error al recuperar las políticas:', error);
      setLoading(false);
    }
  };

  // Función para eliminar una política
  const eliminarPolitica = async (id) => {
    try {
      await axios.delete(`https://pasteleria-api.onrender.com/verPoliticas1/${id}`);
      // Volver a cargar las políticas después de la eliminación
      cargarPoliticas();
    } catch (error) {
      console.error('Error al eliminar la política:', error);
    }
  };

  // Función para guardar cambios en una política editada
  const guardarCambios = async (politica) => {
    try {
      await axios.put(`https://pasteleria-api.onrender.com/verPoliticas1/${politica._id}`, politica);
      // Actualizar el estado para desactivar el modo de edición
      setPoliticas(prevPoliticas => {
        const newPoliticas = prevPoliticas.map(p => {
          if (p._id === politica._id) {
            p.editing = false;
          }
          return p;
        });
        return newPoliticas;
      });
    } catch (error) {
      console.error('Error al guardar cambios en la política:', error);
    }
  };

  useEffect(() => {
    cargarPoliticas();
  }, []);

  return (
    <Container>
      <h2 className="text-center mt-4">Otro Apartado</h2>

      {loading ? (
        <p>Cargando...</p>
      ) : politicas.length === 0 ? (
        <p>No hay políticas disponibles.</p>
      ) : (
        politicas.map((politica, index) => (
          <div key={politica._id} className="mb-4">
            <Form>
              <Form.Group controlId={`politica${index}`}>
                <Form.Label>Política:</Form.Label>
                <Form.Control
                  type="text"
                  value={politica.nombre}
                  readOnly={!politica.editing}
                  onChange={(e) =>
                    setPoliticas(prevPoliticas => {
                      const newPoliticas = [...prevPoliticas];
                      newPoliticas[index].nombre = e.target.value;
                      return newPoliticas;
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId={`descripcion${index}`}>
                <Form.Label>Descripción:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={politica.descripcion}
                  readOnly={!politica.editing}
                  onChange={(e) =>
                    setPoliticas(prevPoliticas => {
                      const newPoliticas = [...prevPoliticas];
                      newPoliticas[index].descripcion = e.target.value;
                      return newPoliticas;
                    })
                  }
                />
              </Form.Group>
            </Form>
            {politica.editing ? (
              <Button
                variant="success"
                onClick={() => guardarCambios(politica)}
              >
                Guardar
              </Button>
            ) : (
              <Button
                variant="warning"
                onClick={() =>
                  setPoliticas(prevPoliticas => {
                    const newPoliticas = [...prevPoliticas];
                    newPoliticas[index].editing = true;
                    return newPoliticas;
                  })
                }
              >
                Editar
              </Button>
            )}
            <Button
              variant="danger"
              className="ms-2"
              onClick={() => eliminarPolitica(politica._id)}
            >
              Eliminar
            </Button>
          </div>
        ))
      )}
    </Container>
  );
}
