
import Swal from 'sweetalert';

const SweetAlert = {
  showAlert: (title, message, type = 'info',botones={ok:"Aceptar"}) => {
    return Swal({
      title: title,
      text: message,
      icon: type,
      buttons: botones
    });
  },
};

const alerta=(title,text,icon)=>{
  SweetAlert.showAlert(title,text,icon)
}
export default alerta;
export const confirmAlert = (title, text, icon = 'info', callback) => {
  return SweetAlert.showAlert(title, text, icon, { ok: "Confirmar", cancel: "Cancelar" })
    .then((result) => {
      if (result) {
        // Ejecutar la función callback si se proporcionó
        
        console.log(result);
      } else {
        console.log(result);
      }
    });
};