import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Importa el componente Link
import routes from '../../helpers/routes'; // Importa las rutas

function PoliticasA() {
  const [nuevaPregunta, setNuevaPregunta] = useState('');
  const [nuevaRespuesta, setNuevaRespuesta] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('https://pasteleria-api.onrender.com/politicas', { // Cambia la URL a http://localhost:4000/politicas
        nombre: nuevaPregunta,
        descripcion: nuevaRespuesta
      });
      alert('Politica y respuesta agregadas exitosamente!');
      setNuevaPregunta('');
      setNuevaRespuesta('');
    } catch (error) {
      console.error('Error al agregar politica y respuesta:', error);
      alert('Hubo un error al agregar la politica y respuesta. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <Container className='mt-3'>
      <h1>Políticas</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formPregunta">
          <Form.Label>politica:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese la politica"
            value={nuevaPregunta}
            onChange={(e) => setNuevaPregunta(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formRespuesta">
          <Form.Label>Respuesta:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Ingrese la respuesta"
            value={nuevaRespuesta}
            onChange={(e) => setNuevaRespuesta(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Agregar Politica
        </Button>
        <Link to={routes.OtroApartado}><Button variant="secondary" className="ms-2">Editar politicas</Button></Link>
      </Form>
    </Container>
  );
}

export default PoliticasA;