import React, { useEffect, useState } from 'react'
import '../css/Toggle.css'
function Toggle({ initialState = false, onChange }) {
    // Estado para controlar si el interruptor está activado o desactivado
    const [isChecked, setIsChecked] = useState(initialState);

    useEffect(() => {
        setIsChecked(initialState);
    }, [initialState]);

    // Función para manejar el cambio de estado del interruptor
    const handleToggle = () => {
        const newState = !isChecked;
        console.log(newState)
        setIsChecked(newState);
        if (onChange) {
            onChange(newState);
        }
    };

    return (
        <div>
            <label className={`switch ${isChecked ? 'checked' : ''}`}>
                <input type="checkbox" checked={isChecked} onChange={() => { }} />
                <span className="slider round" onClick={handleToggle}></span>
            </label>
        </div>
    );
}

export default Toggle