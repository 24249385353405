import React, { useEffect, useState } from 'react'
import '../css/Tank.css'
function Tank({ initialWaterLevel }) {
    const [waterLevel, setWaterLevel] = useState(initialWaterLevel);
    useEffect(() => {
        setWaterLevel(initialWaterLevel);
      }, [initialWaterLevel]);
    return (
        <div className="tank">
            <div className="water" style={{ height: `${waterLevel}%` }}></div>
            <div className="percentage">{waterLevel}%</div>
        </div>
    )
}

export default Tank