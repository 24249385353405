import React, { useState } from 'react'
import '../../css/AdminProductos.css'
import { useForm } from 'react-hook-form';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import useFetch from '../../helpers/useFetch';

function ProductosA() {
  const [reload, setReload] = useState(false);
  const { data, loading, refetch } = useFetch('https://pasteleria-api.onrender.com/productos', {
    params: {
      tipo: 'complemento',
      sabor: 'chocolate'
    }
  }, reload);
  const { data: saboresData, loading: saboresLoading } = useFetch('https://pasteleria-api.onrender.com/productos/sabores');
  const { data: categoriasData, loading: categoriasLoading } = useFetch('https://pasteleria-api.onrender.com/productos/categorias');
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [formDataToUpdate, setFormDataToUpdate] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [complemento, setComplemento] = useState('');
  const [sabor, setSabor] = useState('');
  const [tipoProducto, setTipoProducto] = useState('');
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setTipoProducto('');
  };
  const handleShowModal = (producto) => {
    setFormDataToUpdate(producto);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormDataToUpdate(null);
  };

  const onSubmit = async (formData) => {
    try {
      const form = new FormData();
      form.append('nombre', formData.nombre);
      form.append('descripcion', formData.descripcion);
      form.append('precio', formData.precio);
      form.append('tipo', formData.tipo);
      form.append('sabor', formData.sabor);
      form.append('categoria', formData.categoria);
      form.append('imagen', formData.imagen[0]); // Agrega la imagen al formulario

      const response = await fetch(`https://pasteleria-api.onrender.com/crudProducts`, {
        method: 'POST',
        body: form
      });
      if (response.ok) {
        reset();
        setShowAddModal(false);
        setReload(!reload);
      }
    } catch (error) {
      console.error('Error al crear producto:', error);
    }
  };

  const handleUpdate = async (id, formData) => {
    try {
      const response = await fetch(`https://pasteleria-api.onrender.com/crudProducts/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        reset();
        refetch();
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error al actualizar producto:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("¿Estás seguro de eliminar este producto?")) {
      try {
        const response = await fetch(`https://pasteleria-api.onrender.com/crudProducts/${id}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          refetch();
        }
      } catch (error) {
        console.error('Error al eliminar producto:', error);
      }
    }
  };


  return (
    <div style={{ backgroundImage: 'url("https://th.bing.com/th/id/R.98c1ba7cc6939c2c471c30089bf588d5?rik=boAlxgamO3smtg&pid=ImgRaw&r=0")', backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>
      <Button onClick={handleShowAddModal}>Agregar Producto</Button>

      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)} style={{ alignItems: 'center', width: '80%', margin: 'auto' }}>
            <div style={{ marginBottom: '20px' }}>
              <FloatingLabel label='Nombre'>
                <Form.Control type="text" placeholder="Ingrese el nombre del producto" {...register('nombre', { required: true, pattern: /^[A-Za-z\s]+$/ })} />
                {errors.nombre && errors.nombre.type === "required" && <span style={{ color: 'red' }}>Este campo es obligatorio</span>}
                {errors.nombre && errors.nombre.type === "pattern" && <span style={{ color: 'red' }}>El nombre no puede contener números ni caracteres especiales</span>}
              </FloatingLabel>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <FloatingLabel label='Descripción'>
                <Form.Control type="text" placeholder="Ingrese la descripción del producto" {...register('descripcion', { required: true })} />
                {errors.descripcion && <span style={{ color: 'red' }}>Este campo es obligatorio</span>}
              </FloatingLabel>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <FloatingLabel label='Precio'>
                <Form.Control type="number" placeholder="Ingrese el precio del producto" {...register('precio', { required: true, min: 0, pattern: /^[1-9]\d*$/ })} />
                {errors.precio && errors.precio.type === "required" && <span style={{ color: 'red' }}>Este campo es obligatorio</span>}
                {errors.precio && errors.precio.type === "min" && <span style={{ color: 'red' }}>El precio debe ser mayor que cero</span>}
                {errors.precio && errors.precio.type === "pattern" && <span style={{ color: 'red' }}>El precio debe ser un número entero positivo</span>}
              </FloatingLabel>
            </div>
            <div style={{ marginBottom: '20px' }}>
              <FloatingLabel label='Tipo'>
                <Form.Select {...register('tipo', { required: true })} onChange={e => {
                  setTipoProducto(e.target.value);
                }}>
                  {categoriasLoading ? (
                    <></>
                  ) : (
                    <>
                      <option value={''}>Seleccione una categoria...</option>
                      {categoriasData.map((categoria) => (
                        <option key={categoria._id} value={categoria.categoria.toLowerCase()}>{categoria.categoria}</option>
                      ))}
                    </>
                  )}
                </Form.Select>
                {errors.tipo && <span style={{ color: 'red' }}>Por favor seleccione el tipo de pastel</span>}
              </FloatingLabel>
            </div>
            {tipoProducto !== "complemento" && tipoProducto !== '' && (
              <div style={{ marginBottom: '20px' }}>
                <FloatingLabel label='Sabor'>
                  <Form.Select {...register('sabor')}>
                    {saboresLoading ? (
                      <option>Cargando...</option>
                    ) : (
                      saboresData.map((sabor) => (
                        <option key={sabor._id} value={sabor.sabor.toLowerCase()}>{sabor.sabor}</option>
                      ))
                    )}
                  </Form.Select>
                </FloatingLabel>
              </div>
            )}

            <Form.Group controlId="formImagen" style={{ marginBottom: '20px' }}>
              <Form.Label>Seleccionar Imagen</Form.Label>
              <Form.Control type="file" {...register('imagen')} />
            </Form.Group>
            <Button type='submit' style={{ margin: '0 auto', display: 'block' }}>Agregar Producto</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <div style={{ marginBottom: '10px' }}></div>


      <div className="table-container">
        <h1 style={{ textAlign: "center", fontSize: "36px", color: "black", fontWeight: "bold" }}>Lista de Productos</h1>
        <table className="table">
          <thead>
            <tr className="Subtítulos">
              <th style={{ backgroundColor: "rgb(236, 122, 27)", color: "white" }}>Nombre</th>
              <th style={{ backgroundColor: "rgb(236, 122, 27)", color: "white" }}>Descripción</th>
              <th style={{ backgroundColor: "rgb(236, 122, 27)", color: "white" }}>Precio</th>
              <th style={{ backgroundColor: "rgb(236, 122, 27)", color: "white" }}>Tipo</th>
              <th style={{ backgroundColor: "rgb(236, 122, 27)", color: "white" }}>Acciones</th>
            </tr>
          </thead>
          <tbody className="Texto">
            {loading ? (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>Cargando...</td>
              </tr>
            ) : (
              data.map((producto) => (
                <tr key={producto._id}>
                  <td>{producto.nombre}</td>
                  <td>{producto.descripcion}</td>
                  <td>${producto.precio}</td>
                  <td>{producto.tipo}</td>
                  <td>
                    <button className="btn btn-primary" onClick={() => handleShowModal(producto)}>Modificar</button>
                    <button className="btn btn-danger" onClick={() => handleDelete(producto._id)}>Eliminar</button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{ alignItems: "center" }}>Actualizar Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete='off' onSubmit={handleSubmit((formData) => handleUpdate(formDataToUpdate._id, formData))}>
            <h2>Editar Producto</h2>
            <FloatingLabel label='Nombre'>
              <Form.Control type="text" placeholder="Ingrese el nombre del producto" defaultValue={formDataToUpdate?.nombre} {...register('nombre', { required: true, pattern: /^[A-Za-z\s]+$/ })} />
              {errors.nombre && errors.nombre.type === "required" && <span style={{ color: 'red' }}>Este campo es obligatorio</span>}
              {errors.nombre && errors.nombre.type === "pattern" && <span style={{ color: 'red' }}>El nombre no puede contener números ni caracteres especiales</span>}
            </FloatingLabel>
            <FloatingLabel label='Descripción'>
              <Form.Control type="text" placeholder="Ingrese la descripción del producto" defaultValue={formDataToUpdate?.descripcion} {...register('descripcion', { required: true })} />
              {errors.descripcion && <span style={{ color: 'red' }}>Este campo es obligatorio</span>}
            </FloatingLabel>
            <FloatingLabel label='Precio'>
              <Form.Control type="number" placeholder="Ingrese el precio del producto" defaultValue={formDataToUpdate?.precio} {...register('precio', { required: true, min: 0, pattern: /^[1-9]\d*$/ })} />
              {errors.precio && errors.precio.type === "required" && <span style={{ color: 'red' }}>Este campo es obligatorio</span>}
              {errors.precio && errors.precio.type === "min" && <span style={{ color: 'red' }}>El precio debe ser mayor que cero</span>}
              {errors.precio && errors.precio.type === "pattern" && <span style={{ color: 'red' }}>El precio debe ser un número entero positivo</span>}
            </FloatingLabel>
            <div style={{ marginBottom: '20px' }}>
              <FloatingLabel label='Tipo'>
                <Form.Select {...register('tipo', { required: true })} onChange={e => {
                  setTipoProducto(e.target.value);
                }}>
                  {categoriasLoading ? (
                    <></>
                  ) : (
                    <>
                      <option value={''}>Seleccione una categoria...</option>
                      {categoriasData.map((categoria) => (
                        <option key={categoria._id} value={categoria.categoria.toLowerCase()}>{categoria.categoria}</option>
                      ))}
                    </>
                  )}
                </Form.Select>
                {errors.tipo && <span style={{ color: 'red' }}>Por favor seleccione el tipo de pastel</span>}
              </FloatingLabel>
            </div>
            {tipoProducto !== "complemento" && tipoProducto !== '' && (
              <div style={{ marginBottom: '20px' }}>
                <FloatingLabel label='Sabor'>
                  <Form.Select {...register('sabor')}>
                    {saboresLoading ? (
                      <option>Cargando...</option>
                    ) : (
                      saboresData.map((sabor) => (
                        <option key={sabor._id} value={sabor.sabor.toLowerCase()}>{sabor.sabor}</option>
                      ))
                    )}
                  </Form.Select>
                </FloatingLabel>
              </div>
            )}

            <Form.Group controlId="formImagen" style={{ marginBottom: '20px' }}>
              <Form.Label>Seleccionar Imagen</Form.Label>
              <Form.Control type="file" {...register('imagen')} />
            </Form.Group>
            <Button type='submit'>Actualizar Producto</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductosA;