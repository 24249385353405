import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Importa el componente Link
import routes from '../../helpers/routes'; // Importa las rutas

function AgregarPreguntaForm() {
  const [nuevaPregunta, setNuevaPregunta] = useState('');
  const [nuevaRespuesta, setNuevaRespuesta] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('https://pasteleria-api.onrender.com/pregun', {
        nombre: nuevaPregunta,
        descripcion: nuevaRespuesta
      });
      alert('Pregunta y respuesta agregadas exitosamente!');
      setNuevaPregunta('');
      setNuevaRespuesta('');
    } catch (error) {
      console.error('Error al agregar pregunta y respuesta:', error);
      alert('Hubo un error al agregar la pregunta y respuesta. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <div className='mt-3'>
      <Container>

        <h1>Preguntas frecuentes</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPregunta">
            <Form.Label>Pregunta:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese la pregunta"
              value={nuevaPregunta}
              onChange={(e) => setNuevaPregunta(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formRespuesta">
            <Form.Label>Respuesta:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Ingrese la respuesta"
              value={nuevaRespuesta}
              onChange={(e) => setNuevaRespuesta(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Agregar Pregunta
          </Button>
          <Link to={routes.EditarPreguntas}><Button variant="secondary" className="ms-2">Editar preguntas</Button></Link>
        </Form>
      </Container>
    </div>
  );
}

export default AgregarPreguntaForm;