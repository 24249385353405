import React, { useState, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import axios from 'axios';

export default function EditarPreguntas() {
  const [preguntas, setPreguntas] = useState([]);
  const [loading, setLoading] = useState(true);

  // Función para cargar las preguntas frecuentes
  const cargarPreguntas = async () => {
    try {
      const response = await axios.get('https://pasteleria-api.onrender.com/permite');
      setPreguntas(response.data.map(pregunta => ({ ...pregunta, editing: false }))); // Agregar propiedad "editing" a cada pregunta
      setLoading(false);
    } catch (error) {
      console.error('Error al recuperar las preguntas frecuentes:', error);
      setLoading(false);
    }
  };

  // Función para eliminar una pregunta
  const eliminarPregunta = async (id) => {
    try {
      await axios.delete(`https://pasteleria-api.onrender.com/permite/${id}`); // Corregir la sintaxis del endpoint
      // Volver a cargar las preguntas después de la eliminación
      cargarPreguntas();
    } catch (error) {
      console.error('Error al eliminar la pregunta:', error);
    }
  };

  // Función para guardar cambios en una pregunta editada
  const guardarCambios = async (index) => {
    try {
      const pregunta = preguntas[index];
      await axios.put(`https://pasteleria-api.onrender.com/permite/${pregunta._id}`, pregunta); // Corregir la sintaxis del endpoint
      // Actualizar el estado para desactivar el modo de edición
      setPreguntas(prevPreguntas => {
        const newPreguntas = [...prevPreguntas];
        newPreguntas[index].editing = false;
        return newPreguntas;
      });
    } catch (error) {
      console.error('Error al guardar cambios en la pregunta:', error);
    }
  };

  useEffect(() => {
    cargarPreguntas();
  }, []);

  return (
    <Container>
      <h2 className="text-center mt-4">Editar Preguntas Frecuentes</h2>

      <Form>
        {loading ? (
          <p>Cargando...</p>
        ) : preguntas.length === 0 ? (
          <p>No hay preguntas frecuentes disponibles.</p>
        ) : (
          preguntas.map((pregunta, index) => (
            <div key={index} className="mb-4">
              <Form.Group controlId={`pregunta${index}`}>
                <Form.Label>Pregunta:</Form.Label>
                <Form.Control
                  type="text"
                  value={pregunta.nombre}
                  readOnly={!pregunta.editing}
                  onChange={(e) =>
                    setPreguntas(prevPreguntas => {
                      const newPreguntas = [...prevPreguntas];
                      newPreguntas[index] = { ...newPreguntas[index], nombre: e.target.value };
                      return newPreguntas;
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId={`respuesta${index}`}>
                <Form.Label>Respuesta:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={pregunta.descripcion}
                  readOnly={!pregunta.editing}
                  onChange={(e) =>
                    setPreguntas(prevPreguntas => {
                      const newPreguntas = [...prevPreguntas];
                      newPreguntas[index] = { ...newPreguntas[index], descripcion: e.target.value };
                      return newPreguntas;
                    })
                  }
                />
              </Form.Group>
              {pregunta.editing ? (
                <Button
                  variant="success"
                  onClick={() => guardarCambios(index)}
                >
                  Guardar
                </Button>
              ) : (
                <Button
                  variant="warning"
                  onClick={() =>
                    setPreguntas(prevPreguntas => {
                      const newPreguntas = [...prevPreguntas];
                      newPreguntas[index] = { ...newPreguntas[index], editing: true };
                      return newPreguntas;
                    })
                  }
                >
                  Editar
                </Button>
              )}
              <Button
                variant="danger"
                className="ms-2"
                onClick={() => eliminarPregunta(pregunta._id)}
              >
                Eliminar
              </Button>
            </div>
          ))
        )}
      </Form>
    </Container>
  );
}
