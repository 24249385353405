import AuthProvider from '../../auth/AuthProvider';
import React, { useState, useEffect } from 'react';
import useFetch from '../../helpers/useFetch';
import { Form, Button, Card, FloatingLabel, Alert, Container, Nav, Row, Col } from 'react-bootstrap';
import useAuth from '../../auth/useAuth';
import { loginRequest, cambiarContraseña } from '../../api/auth';
import SweetAlert from '../../helpers/SweetAlert';

function Cuenta() {
  const alerta = (title, text, icon) => {
    SweetAlert.showAlert(title, text, icon)
  }

  const { user } = useAuth();
  const { id } = user;

  const [userId, setUserId] = useState(id); // Inicializar userId con el id del usuario

  useEffect(() => {
    setUserId(id); // Actualizar userId cuando cambie el id del usuario
  }, [id]);

  const { data: userData, loading } = useFetch(`https://pasteleria-api.onrender.com/usuarios/${userId}`);

  const email = userData && userData.datosCuenta && userData.datosCuenta.email;

  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);

  const handlePasswordInputClick = () => {
    setShowPasswordPrompt(true);
  };

  const handlePasswordPromptClose = () => {
    setShowPasswordPrompt(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Evitar la recarga de la página
    const bandera = 1;
    // Realizar la solicitud de cambio de contraseña al backend
    try {
      const userCredentials = { email, password, bandera };
      await loginRequest(userCredentials);
      alerta('Éxito', 'Comprobacion en la contraseña', 'success');
      setShowPasswordPrompt(false); // Cerrar el modal después de comprobar la contraseña
      setPasswordValid(true);
    } catch (error) {
      alerta('Error', error.response.data.message, 'error');
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const rePass = document.getElementById('confirmPassword').value;
    const password1 = document.getElementById('password1').value;
    console.log("Checa la contra", rePass)

    if (password1 !== rePass) {
      alerta('Error', 'Las contraseñas no coinciden', 'error');
      return;
    }

    try {
      const userId = id;
      console.log("Checa el id", userId, "y la contra", newPassword);

      await cambiarContraseña(userId, newPassword);
      alerta('Éxito', 'Contraseña cambiada exitosamente', 'success');
      setPasswordValid(true);
    } catch (error) {
      console.log("error")
      alerta('Error', error.response.data.message, 'error');
    }
  };


  return (
    <AuthProvider>
      <div style={{ backgroundImage: 'url("https://png.pngtree.com/background/20230618/original/pngtree-dark-pastel-birthday-party-3d-rendering-of-a-cake-with-candles-picture-image_3757072.jpg")', width: '100%', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <h1 style={{ padding: '20px', justifyContent: 'center', alignItems: 'center', color: 'white' }}>Perfil</h1>
        <div style={{ display: 'flex', width: '80%', height: '68%', maxWidth: '1200px', maxHeight: '800px', flexDirection: 'row' }}>
          {
            loading ? (<></>) : (
              <>
                <div style={{ flex: 1, padding: '20px', borderRight: '2px solid #ccc' }}>
                  <img src="https://i0.wp.com/codigoespagueti.com/wp-content/uploads/2022/06/Spy-x-Family-Tatsuya-Endo-muestra-un-hilarante-dibujo-de-Anya-Forger.jpg?resize=1280%2C720&quality=80&ssl=1" alt="Foto de perfil" style={{ width: '100%', height: 'auto', border: '3px solid #fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: '13px' }} />
                </div>
                <div style={{ flex: 1, padding: '20px' }}>
                  <form id="changePasswordForm" onSubmit={handleFormSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                      <FloatingLabel htmlFor="nombre" style={{ color: "white", fontSize: "18px", marginBottom: '5px' }}><strong>Nombre:</strong>{userData.nombre.nombre} {userData.nombre.apP} {userData.nombre.apM}</FloatingLabel>
                    </div>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                      <FloatingLabel htmlFor="email" style={{ color: "white", fontSize: "18px", marginBottom: '5px' }}><strong>Email:</strong> {email}</FloatingLabel>
                    </div>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                      <label htmlFor="telefono" style={{ color: "white", fontSize: "18px", marginBottom: '5px' }}><strong>Teléfono:</strong> {userData && userData.telefono}</label>
                    </div>
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                      <FloatingLabel htmlFor="password" style={{ color: "white", fontSize: "18px", marginBottom: '5px' }}><strong>Contraseña nueva:</strong></FloatingLabel>
                      <input type="password" id="password1" name="password1" minLength={8} pattern="{ value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/}" required style={{ width: '80%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onClick={handlePasswordInputClick} />
                    </div>
                    <input type="hidden" id="userId" name="userId" value={userId} />
                    <div style={{ marginBottom: '20px', width: '100%' }}>
                      <FloatingLabel htmlFor="confirmPassword" style={{ color: "white", fontSize: "18px", marginBottom: '5px' }}><strong>Confirmar contraseña nueva:</strong></FloatingLabel>
                      <input type="password" id="confirmPassword" name="confirmPassword" minLength={8} pattern="{ value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/}" required style={{ width: '80%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} onChange={handleNewPasswordChange} />
                    </div>
                    <br />
                    <button type="submit" style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer', fontSize: '16px' }}>Cambiar contraseña</button>
                  </form>
                </div>
              </>
            )
          }
        </div>
      </div>
      {showPasswordPrompt && !passwordValid && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999 }} onClick={handlePasswordPromptClose}>
          <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', maxWidth: '400px', width: '80%', textAlign: 'center' }} onClick={(e) => e.stopPropagation()}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="passwordPrompt" style={{ marginBottom: '10px', display: 'block', fontSize: '18px' }}>Verifique su contraseña:</label>
              <input type="password" id="passwordPrompt" name="passwordPrompt" value={password} onChange={handlePasswordChange} style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px', boxSizing: 'border-box' }} />
              <button type="submit" style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer', fontSize: '16px' }}>Comprobar</button>
            </form>
          </div>
        </div>

      )}
    </AuthProvider>
  );
}

export default Cuenta;