const routes = {
    home: '/',
    login: '/login',
    registro: '/register',
    recuperacion:'/recovery',
    recuperacionMetodo:'/recovery/method/',
    recuperacionEmail:'email',
    recuperacionToken:'token',
    recuperacionQuestion:'question',
    cambiarPass:'/change-pass',
    catalogoFamiliar: '/products/familiares',
    catalogoIndividual: '/products/individuales',
    catalogoComplementos: '/products/complementos',
    detalleProducto: '/products/:id',
    quienesSomos: '/about',
    contacto: '/contact',
    politicas: '/policy',
    faq: '/faq',
    comentarios: '/comments',
    bienvenida: '/welcome',
    perfil: '/account',
    comentariosU: '/user/comments',
    iotU:'/user/iot/',
    iotDashboardU: 'dashboard',
    iotDevicesU:'devices',
    iotDeviceU:'devices/:id',
    iotVariableU:'devices/:id/variable',
    usuariosA: '/admin/users',
    productsA: '/admin/products',
    iotA: '/admin/iot',
    politicasA: '/admin/politicas',
    faqA: '/admin/faq',
    PreguntasA:'/admin/preguntasA',
    OtroApartado:'/admin/OtroApartado',
    EditarPreguntas:'/admin/EditarPreguntas'
}

export default routes;