import React from 'react'
import { Outlet } from 'react-router-dom'


export default function IoTLayout() {
    return (
        <div style={{ width: '100%', backgroundColor: '#F0F0F0', flex: 1, minHeight: '60vh' }}>
            <Outlet />
        </div>
    )
}
