import React from 'react';

const BotonSVG = ({ fill, width, height, onClick }) => {
  return (
    <svg fill={fill} width={width} height={height} viewBox="-1.7 0 20.4 20.4" xmlns="http://www.w3.org/2000/svg" className="cf-icon-svg" id="led" onClick={onClick}>
      <path
        id="myPath"
        d="M16.416 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.916 7.917zm-2.854-.455a5.154 5.154 0 0 0-.331-1.28 5.087 5.087 0 0 0-.68-1.194 5.008 5.008 0 0 0-.968-.97.792.792 0 0 0-.954 1.264 3.434 3.434 0 0 1 .663.666 3.498 3.498 0 0 1 .468.821 3.587 3.587 0 0 1 .23.887 3.507 3.507 0 0 1-.005.9 3.488 3.488 0 0 1-.687 1.659 3.558 3.558 0 0 1-1.487 1.132 3.653 3.653 0 0 1-.89.23 3.431 3.431 0 0 1-.895-.005 3.637 3.637 0 0 1-.874-.235 3.54 3.54 0 0 1-.793-.456 3.5 3.5 0 0 1-.668-.67 3.464 3.464 0 0 1-.463-.816 3.513 3.513 0 0 1-.22-1.785 3.51 3.51 0 0 1 .234-.87 3.578 3.578 0 0 1 .457-.795 3.472 3.472 0 0 1 .668-.665.792.792 0 0 0-.959-1.26 5.056 5.056 0 0 0-.972.97 5.158 5.158 0 0 0-.658 1.147 5.104 5.104 0 0 0-.022 3.843 5.035 5.035 0 0 0 .675 1.19 5.088 5.088 0 0 0 2.121 1.632 5.23 5.23 0 0 0 1.256.338 5.01 5.01 0 0 0 1.31.009 5.23 5.23 0 0 0 1.277-.33 5.147 5.147 0 0 0 2.166-1.649 5.1 5.1 0 0 0 1-3.708zm-5.858-.223a.792.792 0 1 0 1.584 0V5.429a.792.792 0 1 0-1.584 0z"
      />
    </svg>
  );
};

export default BotonSVG;
