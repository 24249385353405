import React, { useState, useEffect } from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';
import axios from 'axios';

function FAQ() {
  const [preguntas, setPreguntas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPreguntas = async () => {
      try {
        const response = await axios.get('https://pasteleria-api.onrender.com/permite');
        // Verifica si la respuesta es un array, si no, conviértela en un array
        const preguntasData = Array.isArray(response.data) ? response.data : [response.data];
        setPreguntas(preguntasData);  
        setLoading(false);
      } catch (error) {
        console.error('Error al recuperar las preguntas frecuentes:', error);
        setLoading(false);
      }
    };
    
    fetchPreguntas();
  }, []);

  return (
    <Container>
      <h2 className="text-center mt-4">Preguntas Frecuentes</h2>
      <Accordion className="container mb-4">
        <Card>
          {loading ? (
            <p>Cargando...</p>
          ) : preguntas.length === 0 ? (
            <p>No hay preguntas frecuentes disponibles.</p>
          ) : (
            preguntas.map((pregunta, index) => (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>{pregunta.nombre}</Accordion.Header>
                <Accordion.Body>
                  {pregunta.descripcion[0]} {/* Selecciona la primera descripción */}
                </Accordion.Body>
              </Accordion.Item>
            ))
          )}
        </Card>
      </Accordion>
    </Container>
  );
}

export default FAQ;