import React, { useEffect, useState } from 'react'
import '../../css/diseñoPrincipal.css'
import { Carousel } from 'react-bootstrap';
import useFetch from '../../helpers/useFetch';
export default function Home() {
  const [productos, setProductos] = useState([]);
  const { data: productosData, loading } = useFetch('https://pasteleria-api.onrender.com/productos');

  useEffect(() => {
    if (productosData) {
      setProductos(productosData);
    }
  }, [productosData]);

  return (
    <div className="container welcome-page">
      <header className="text-center">
        <h1>Bienvenido a nuestra página</h1>
      </header>
      <main className="row">
        <section className="col-lg-6">
          <div className="hero">
            <h2 className="Subtítulos">¡El sabor que te conquista!</h2>
            <p>Descubre nuestra variedad de productos frescos y deliciosos.</p>
          </div>
          <div>
            <h2 className="Subtítulos">Nuestra Historia de Pasteles</h2>
            <p>
              En nuestro pequeño pueblo, la tradición de hacer pasteles ha pasado de generación en generación. Desde los primeros días de nuestra panadería, nos hemos esforzado por crear pasteles que deleiten a todos los que los prueban. Nuestros ingredientes frescos y nuestra pasión por la repostería se combinan para ofrecer los pasteles más deliciosos y hermosos que jamás hayas visto.
            </p>
          </div>
        </section>
        <section className="col-lg-6 carousel-section">
          <Carousel className="carousel-container">
            {productos.map(producto => (
              <Carousel.Item key={producto._id}>
                <img
                  className="d-block w-100"
                  src={producto.imagen}
                  alt={producto.nombre}
                />
                <Carousel.Caption>
                  <h3>{producto.nombre}</h3>
                  <p>{producto.descripcion}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </section>
      </main>
    </div>
);
}
