import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import Toggle from '../../../helpers/Toggle'
import '../../../css/Dashboard.css'
import useFetch from '../../../helpers/useFetch'
import useAuth from '../../../auth/useAuth'
import { asignarDispositivoRequest, dispositivoRequest, variableRequest } from '../../../api/device'
import Tank from '../../../helpers/Tank'
import BotonSVG from '../../../components/BotonSVG'
import alerta from '../../../helpers/SweetAlert'

const URL_API = 'https://pasteleria-api.onrender.com'
//https://pasteleria-api-dev-cgrq.3.us-1.fl0.io
function Dashboard() {
  const { user } = useAuth()
  const [reload, setReload] = useState(false);
  const [dataWidgets, setDataWidgets] = useState(null)
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [loadingWidgets, setLoadingWidgets] = useState(true);
  const [newDeviceCode, setNewDeviceCode] = useState("");
  const { data: dataDevices, loading: loadingDevices } = useFetch(URL_API + '/devices/user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id: user.id })
  }, reload)
  async function cargarDispositivo(idDevice) {
    try {
      const response = await dispositivoRequest(idDevice)
      setDataWidgets(response.data.dispositivo)
      setLoadingWidgets(!loadingWidgets)
      setLuces(response.data.dispositivo.luces)
      setTurbinas(response.data.dispositivo.turbinas)
      setBomba(response.data.dispositivo.bomba)
      setAutomatico(response.data.dispositivo.automatico)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // Función para cargar el dispositivo y establecer el intervalo
    const cargarDispositivoYEstablecerIntervalo = async () => {
      // Cargar dispositivo si hay dispositivos y no hay ninguno seleccionado
      if (!loadingDevices && dataDevices && dataDevices.length > 0 && !selectedDeviceId) {
        setSelectedDeviceId(dataDevices[0].idDispositivo._id);
      }

      // Cargar dispositivo si hay un dispositivo seleccionado
      if (selectedDeviceId) {
        await cargarDispositivo(selectedDeviceId);
      }
    };
    // Llamar a la función inicialmente
    cargarDispositivoYEstablecerIntervalo();
    // Establecer el intervalo para cargar el dispositivo cada 65 segundos
    const intervalId = setInterval(cargarDispositivoYEstablecerIntervalo, 5000);
    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [loadingDevices, dataDevices, selectedDeviceId]);
  //Cargar los datos del dispositivo seleccionado
  const handleChange = event => {
    const selectedValue = event.target.value;
    setSelectedDeviceId(selectedValue); // Actualizar el ID del dispositivo seleccionado
    setLoadingWidgets(!loadingWidgets)
    cargarDispositivo(selectedValue);
  };
  const [luces, setLuces] = useState(0);
  const [bomba, setBomba] = useState(0); // Estado de la bomba
  const [turbinas, setTurbinas] = useState(0); // Estado de las turbinas
  const [automatico, setAutomatico] = useState(true); // Estado del control automatico

  // Manejador para cambiar el estado de las luces
  const handleLuces = async (clienteID) => {
    const response = await variableRequest(clienteID, 'Luces', luces === 1 ? 0 : 1)
    console.log(response)
    if (luces === 1)
      setLuces(0)
    else if (luces === 0)
      setLuces(1)
  }
  // Manejador para cambiar el estado de la bomba
  const handleBomba = async (clienteID) => {
    const response = await variableRequest(clienteID, 'Bomba', bomba === 1 ? 0 : 1)
    console.log(response)
    const newBombaState = !bomba;
    setBomba(newBombaState);
    console.log('Bomba:', newBombaState);
  };
  // Manejador para cambiar el estado de las turbinas
  const handleTurbinas = async (clienteID) => {
    const response = await variableRequest(clienteID, 'Turbinas', turbinas === 1 ? 0 : 1)
    console.log(response)
    const newTurbinasState = !turbinas;
    setTurbinas(newTurbinasState);
    console.log('Turbinas:', newTurbinasState);
  };
  // Manejador para cambiar el estado de control del dispositivo
  const handleAutomatico = async (clienteID) => {
    const response = await variableRequest(clienteID, 'Automatico', automatico ? false : true)
    console.log(response)
    const newAutomaticoState = !automatico;
    setAutomatico(newAutomaticoState);
    console.log('Automatico:', newAutomaticoState);
  };
  const [showModal, setShowModal] = useState(false);
  const handleAddDevice = async () => {
    // Aquí puedes realizar la acción de enviar el código del dispositivo al servidor
    console.log("Código del dispositivo:", newDeviceCode);
    try {
      const response = await asignarDispositivoRequest(newDeviceCode, user.id)
      console.log(response)
      setLoadingWidgets(!loadingWidgets)
      setReload(!reload)
      setShowModal(false);
      setNewDeviceCode("");
    } catch (error) {
      setNewDeviceCode("");
      alerta('Error', error.response.data.error, 'error')
    }
  };

  return (
    <Container className='mt-3'>
      {
        dataDevices && dataDevices.length > 0 ? (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }} className='mb-3 pt-2'>
              <h1>Dashboard</h1>
              <div style={{ display: 'flex' }}>
                <Form.Select onChange={handleChange} style={{ width: 'max-content' }}>
                  {
                    loadingDevices ? (<></>) : (
                      dataDevices.map(dispositivo => (
                        <option key={dispositivo._id} value={dispositivo.idDispositivo._id}>
                          {dispositivo.idDispositivo.label ? dispositivo.idDispositivo.label : dispositivo.idDispositivo.clave}
                        </option>
                      ))
                    )
                  }
                </Form.Select>
                <Button onClick={() => setShowModal(true)}>Agregar dispositivo</Button>
              </div>
            </div>

            {
              loadingWidgets ? (<></>) : (
                <Row sm={2} md={3} lg={4} >
                  {/* Control automático */}
                  <Col className='mb-2'>
                    <Card>
                      <Container className='p-2'>
                        <Card.Title>Control</Card.Title>
                        <Card.Body className='text-center'>
                          <Toggle id="motores" initialState={automatico} onChange={()=>handleAutomatico(dataWidgets.clienteID)} />
                          <p>{automatico ? 'Automático' : 'Manual'}</p>
                        </Card.Body>
                      </Container>
                    </Card>
                  </Col>
                  {/* Luces LED */}
                  <Col className='mb-2'>
                    <Card>
                      <Container className='p-2'>
                        <Card.Title>Luces</Card.Title>
                        <Card.Body className='text-center'>
                          <BotonSVG fill={luces === 1 ? 'green' : 'grey'} width="130px" height="130px" onClick={() => handleLuces(dataWidgets.clienteID)} />
                          <p>{luces === 1 ? 'ON' : 'OFF'}</p>
                        </Card.Body>
                      </Container>
                    </Card>
                  </Col>
                  {/* Turbinas */}
                  <Col className='mb-2'>
                    <Card>
                      <Container className='p-2'>
                        <Card.Title>Ventilación</Card.Title>
                        <Card.Body className='text-center'>
                          <Toggle id="motores" initialState={turbinas === 1 ? true : false} onChange={()=>handleTurbinas(dataWidgets.clienteID)} />
                          <p>{turbinas === 1 ? 'ON' : 'OFF'}</p>
                        </Card.Body>
                      </Container>
                    </Card>
                  </Col>
                  <Col className='mb-2'>
                    <Card>
                      <Container className='p-2'>
                        <Card.Title>Temperatura</Card.Title>
                        <Card.Body className='text-center'>
                          {/* Temperatura */}
                          <h2>{dataWidgets.temperatura} °C</h2>
                        </Card.Body>
                      </Container>
                    </Card>
                  </Col>
                  <Col className='mb-2'>
                    <Card>
                      <Container className='p-2'>
                        <Card.Title>Humedad relativa</Card.Title>
                        <Card.Body style={{ display: 'flex', alignItems: 'center' }}>
                          <Tank initialWaterLevel={dataWidgets.humedadRelativa} />
                          <p></p>
                        </Card.Body>
                      </Container>
                    </Card>
                  </Col>
                  <Col className='mb-2'>
                    <Card>
                      <Container className='p-2'>
                        <Card.Title>Humedad del suelo</Card.Title>
                        <Card.Body style={{ display: 'flex', alignItems: 'center' }}>
                          <Tank initialWaterLevel={dataWidgets.humedadSuelo} />
                        </Card.Body>
                      </Container>
                    </Card>
                  </Col>
                  <Col className='mb-2'>
                    <Card>
                      <Container className='p-2'>
                        <Card.Title>Riego</Card.Title>
                        <Card.Body className='text-center'>
                          <Toggle id="bomba" initialState={bomba === 1 ? false : true} onChange={()=>handleBomba(dataWidgets.clienteID)} />
                          <p>{bomba === 1 ? 'OFF' : 'ON'}</p>
                        </Card.Body>
                      </Container>
                    </Card>
                  </Col>
                  <Col className='mb-2'>
                    <Card>
                      <Container className='p-2'>
                        <Card.Title>Luminosidad</Card.Title>
                        <Card.Body className='text-center'>
                          <h2>{dataWidgets.luminosidad}</h2>
                        </Card.Body>
                      </Container>
                    </Card>
                  </Col>
                </Row>
              )
            }
          </div>
        ) : (
          <div className='text-center pt-5'>
            <Card className='p-3'>
              <Container>
                <Card.Title>Aún no tiene dispositivos</Card.Title>
                <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
                  Registre su primer dispositivo:
                  <Button onClick={() => setShowModal(true)}>Agregar dispositivo</Button>
                </Card.Body>
              </Container>
            </Card>
          </div>
        )
      }
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar un dispositivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete='off'>
            <FloatingLabel label='Código del dispositivo'>
              <Form.Control type='text' placeholder='Ingrese el código del dispositivo'
                value={newDeviceCode} // Enlaza el valor del campo con el estado
                onChange={(e) => setNewDeviceCode(e.target.value)} // Manejador para actualizar el estado
              ></Form.Control>
            </FloatingLabel>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleAddDevice} disabled={newDeviceCode===""}>Agregar</Button> {/* Llama a la función de envío */}
        </Modal.Footer>
      </Modal>
    </Container>

  )
}

export default Dashboard