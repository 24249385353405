import React from 'react'
import { Navbar, Nav, Image } from 'react-bootstrap'
import { NavLink } from "react-router-dom";
import useAuth from '../auth/useAuth';
import routes from '../helpers/routes';
import IotNavigation from './IotNavigation';
export default function Navigation() {
    const { isLogged, logout, hasRole } = useAuth();
    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant='dark' style={{ backgroundColor: '#320C18' }}>
                <Navbar.Brand as={NavLink} to={routes.home} className='ms-5' style={{ display: 'flex', justifyContent: 'center' }}>
                    <Image src='logo20.png' alt='Logo' style={{ height: 30 }} className='me-3' />
                    LIVELY
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav className="mx-auto " variant='underline'>
                        {/* Modificar las rutas para mostrar los productos */}
                        <Nav.Link as={NavLink} to={routes.catalogoFamiliar}>Familiares</Nav.Link>
                        <Nav.Link as={NavLink} to={routes.catalogoIndividual}>Individuales</Nav.Link>
                        <Nav.Link as={NavLink} to={routes.catalogoComplementos}>Complementos</Nav.Link>
                        {/* Modificar por el nombre del iot */}
                        {/* <Nav.Link as={NavLink} to={routes.contacto}>Contacto</Nav.Link> */}

                    </Nav>
                    <Nav className='me-4' variant='underline'>
                        {isLogged() ? (<>
                            <Nav.Link as={NavLink} to={routes.perfil}>
                                Mi perfil
                            </Nav.Link>
                            <Nav.Link onClick={() => logout()}>
                                Cerrar sesión
                            </Nav.Link>
                        </>
                        ) : (
                            <>
                                <Nav.Link as={NavLink} to={routes.login}>
                                    Login
                                </Nav.Link>
                                <Nav.Link as={NavLink} to={routes.registro}>
                                    Registro
                                </Nav.Link>
                            </>
                        )}
                    </Nav>


                </Navbar.Collapse>
            </Navbar>
            {isLogged() ? (
                <Navbar collapseOnSelect expand="lg" variant='dark' style={{ backgroundColor: '#4F1426' }}>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav className="ms-auto" style={{ margin: 'auto' }} variant='underline'>
                            {hasRole('admin') ? (
                                <>
                                    <Nav.Link as={NavLink} to={routes.usuariosA}>Usuarios</Nav.Link>
                                    <Nav.Link as={NavLink} to={routes.productsA}>Productos</Nav.Link>
                                    <Nav.Link as={NavLink} to={routes.politicasA}>Políticas</Nav.Link>
                                    <Nav.Link as={NavLink} to={routes.faqA}>FAQ</Nav.Link>
                                    <Nav.Link as={NavLink} to={routes.iotA}>IoT</Nav.Link>
                                </>
                            ) : hasRole('usuario') ? (
                                <IotNavigation />
                            ) : (<></>)}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            ) : (<></>)}
        </>
    )
}
