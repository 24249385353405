import React, { useState, useEffect } from 'react';
// import '../../css/AdminUsuarios.css'; // Asegúrate de tener tu archivo CSS para estilos
// import { Dialog } from 'primereact/dialog';
// import { Button } from 'primereact/button';
import '../../css/Modal.css';

function UsuariosA() {
  const [usuarios, setUsuarios] = useState([]);
  const [usuarioModificar, setUsuarioModificar] = useState({
    _id: '',
    nombre: { nombre: '', apP: '', apM: '' },
    telefono: '',
    datosCuenta: { email: '', rol: '' },
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = async () => {
    try {
      const response = await fetch('https://pasteleria-api.onrender.com/usuarios');
      if (!response.ok) {
        throw new Error('No se pudieron cargar los usuarios');
      }
      const data = await response.json();
      setUsuarios(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEliminarUsuario = async (id) => {
    try {
      const response = await fetch(`https://pasteleria-api.onrender.com/Usuarios/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('No se pudo eliminar el usuario');
      }
      fetchUsuarios();
    } catch (error) {
      console.error(error);
    }
  };

  const handleModificarUsuario = (usuario) => {
    setUsuarioModificar(usuario);
    setVisible(true);
  };

  const handleSubmitModificarUsuario = async (e) => {
    e.preventDefault(); // Evita que el formulario se envíe de forma automática
    try {
      const response = await fetch(`https://pasteleria-api.onrender.com/Usuarios/${usuarioModificar._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(usuarioModificar),
      });
      if (!response.ok) {
        throw new Error('No se pudo modificar el usuario');
      }
      fetchUsuarios();
      setUsuarioModificar({
        _id: '',
        nombre: { nombre: '', apP: '', apM: '' },
        telefono: '',
        datosCuenta: { email: '', rol: '' },
      });
      setVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="table-container">
        <h1 style={{ textAlign: 'center' }}>Lista de Usuarios</h1>
        <table className="table">
          <thead>
            <tr className="Subtítulos">
              <th>Nombre</th>
              <th>Teléfono</th>
              <th>Correo</th>
              <th>Rol</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody className="Texto">
            {usuarios.map((usuario) => (
              <tr key={usuario._id}>
                <td>{usuario.nombre.nombre} {usuario.nombre.apP} {usuario.nombre.apM}</td>
                <td>{usuario.telefono}</td>
                <td>{usuario.datosCuenta.email}</td>
                <td>{usuario.datosCuenta.rol}</td>
                <td>
                  <button className="btn btn-primary" onClick={() => handleModificarUsuario(usuario)}>Modificar</button>
                  <button className="btn btn-danger" onClick={() => handleEliminarUsuario(usuario._id)}>Eliminar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* <Dialog header="Modificar Usuario" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
      <form onSubmit={handleSubmitModificarUsuario}>
        <div className="form-container">
          <h2>Modificar Usuario</h2> */}
      {/* Campos del formulario */}
      {/* <label>Nombre </label>
          <input type="text" value={usuarioModificar.nombre.nombre} onChange={(e) => setUsuarioModificar({ ...usuarioModificar, nombre: { ...usuarioModificar.nombre, nombre: e.target.value } })} />
          <label>Apellido Paterno </label>
          <input type="text" value={usuarioModificar.nombre.apP} onChange={(e) => setUsuarioModificar({ ...usuarioModificar, nombre: { ...usuarioModificar.nombre, apP: e.target.value } })} />
          <label>Apellido Materno </label>
          <input type="text" placeholder="Ingrese Apellido Materno" value={usuarioModificar.nombre.apM} onChange={(e) => setUsuarioModificar({ ...usuarioModificar, nombre: { ...usuarioModificar.nombre, apM: e.target.value } })} />
          <label>Teléfono </label>
          <input type="text" value={usuarioModificar.telefono} onChange={(e) => setUsuarioModificar({ ...usuarioModificar, telefono: e.target.value })} />
          <label>Correo </label>
          <input type="text" value={usuarioModificar.datosCuenta.email} onChange={(e) => setUsuarioModificar({ ...usuarioModificar, datosCuenta: { ...usuarioModificar.datosCuenta, email: e.target.value } })} />
          <label>Rol </label>
          <input type="text" value={usuarioModificar.datosCuenta.rol} onChange={(e) => setUsuarioModificar({ ...usuarioModificar, datosCuenta: { ...usuarioModificar.datosCuenta, rol: e.target.value } })} />
          <button type="submit" className="save-button">Guardar Cambios</button> */}
      {/* </div>
      </form>
    </Dialog> */}
    </div>
  );
}

export default UsuariosA;