import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export default function Producto() {
  const { id } = useParams(); // Obtiene el ID del producto de la URL
  const [producto, setProducto] = useState(null);

  useEffect(() => {
    fetch(`https://pasteleria-api.onrender.com/productos/${id}`)
      .then(response => response.json())
      .then(data => setProducto(data))
      .catch(error => console.error('Error fetching producto:', error));
  }, [id]);

  if (!producto) {
    return <p>Cargando...</p>;
  }


  return (
    <Form style={{ display: "flex", flexDirection: "column", alignItems: "center", minHeight: "50vh" }}>
      <div style={{ marginBottom: "20px" }}>
        <h1>Detalle del producto</h1>
      </div>
      <div className="container product-detail-container" style={{ maxWidth: "500px", border: "2px solid #000", backgroundColor: "#fff8dc", padding: "20px", borderRadius: "10px" }}>
        <div className="product-detail-info">
          <div className="product-detail-image text-center mb-3">
            <img
              src={producto.imagen}
              alt={producto.nombre}
              className="img-fluid rounded"
              style={{ width: 300, height: 300 }}
            />
          </div>
          <h2 className="product-detail-title Subtítulos">{producto.nombre}</h2>
          <p className="product-detail-description Texto">{producto.descripcion}</p>
          <div className="product-detail-details Texto">
            <p><strong>Tipo:</strong> {producto.tipo}</p>
            <p><strong>Precio:</strong> ${producto.precio}</p>
            <p><strong>Stock: </strong> {producto.stock}</p>
          </div>
          <div className="product-detail-buttons text-center">
            <Button variant="primary">Comprar</Button>
          </div>
        </div>
      </div>
    </Form>
  );
}