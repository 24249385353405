import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { NavLink } from "react-router-dom";
import routes from '../helpers/routes';
export default function IotNavigation() {
    return (
        <div>
            <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav className="mx-auto " variant='underline'>
                    <Nav.Link as={NavLink} to={routes.iotU + routes.iotDashboardU}>Dashboard</Nav.Link>
                    <Nav.Link as={NavLink} to={routes.iotU + routes.iotDevicesU}>Dispositivos</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </div>
    )
}
