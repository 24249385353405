import axios from "axios";
//const API = 'https://pasteleria-api-dev-cgrq.3.us-1.fl0.io/devices'
const API = 'https://pasteleria-api.onrender.com/devices'

export const dispositivosRequest = () => axios.get(`${API}`)
export const dispositivoRequest = (dispositivoID) => axios.get(`${API}/${dispositivoID}`)
export const agregarDispositivoRequest = () => axios.post(`${API}`)
export const asignarDispositivoRequest = (dispositivoID, userID) => axios.post(`${API}/user/${userID}`, { dispositivoID: dispositivoID })
export const eliminarDispositivoRequest = (dispositivoID) => axios.delete(`${API}/${dispositivoID}`)
export const usuariosRequest = () => axios.get(`${API}/usuarios`)

export const dispositivosUserRequest = (userID) => axios.post(`${API}/user`, { id: userID })
export const etiquetaRequest = (dispositivoID, label) => axios.post(`${API}/${dispositivoID}/label`, { label: label })
export const variableRequest = (dispositivoID, variable, valor) => axios.post(`${API}/actualizarVariable/${dispositivoID}`, { variable: variable, valor: valor })