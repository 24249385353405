import React, { useState } from 'react'
import { Button, Card, Container, FloatingLabel, Form, Modal, Table } from 'react-bootstrap'
import useAuth from '../../../auth/useAuth'
import useFetch from '../../../helpers/useFetch'
import Cargando from '../../../components/Cargando'
import { Link } from 'react-router-dom'
import routes from '../../../helpers/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTabletButton } from '@fortawesome/free-solid-svg-icons'
import alerta from '../../../helpers/SweetAlert'
import { asignarDispositivoRequest } from '../../../api/device'

function Devices() {
    const { user } = useAuth()
    const [reload, setReload] = useState(false);
    const { data, loading } = useFetch('https://pasteleria-api.onrender.com/devices/user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: user.id })
    }, reload)
    const formatFecha = (fecha) => {
        return new Date(fecha).toLocaleString();
    };
    const [newDeviceCode, setNewDeviceCode] = useState("");
    const [showModal, setShowModal] = useState(false);
    const handleAddDevice = async () => {
        // Aquí puedes realizar la acción de enviar el código del dispositivo al servidor
        console.log("Código del dispositivo:", newDeviceCode);
        try {
            const response = await asignarDispositivoRequest(newDeviceCode, user.id)
            console.log(response)
            setReload(!reload)
        } catch (error) {
            alerta('Error', error, 'error')
        }
    };
    return (
        <Container className='mt-3' style={{ backgroundColor: 'white', minHeight: '60vh' }}>
            {loading ? (
                <Cargando />
            ) : (
                <div>
                    {
                        data.length > 0 ? (
                            <div>

                                <div style={{ display: 'flex', alignContent: 'center' }}>
                                    <FontAwesomeIcon className='me-3' icon={faTabletButton} style={{ color: "#000000", height: 40 }} />
                                    <h1>Dispositivos</h1>
                                </div>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <td>Dispositivo</td>
                                            <td>Última actividad</td>
                                            <td>Creado en</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.map(dispositivo => (
                                            <tr key={dispositivo._id}>
                                                <td><Link to={routes.iotU + `devices/${dispositivo.idDispositivo._id}`}>{dispositivo.idDispositivo.label ? dispositivo.idDispositivo.label : dispositivo.idDispositivo._id}</Link></td>
                                                <td>{formatFecha(dispositivo.idDispositivo.updatedAt)}</td>
                                                <td>{formatFecha(dispositivo.idDispositivo.createdAt)}</td>
                                                <td>
                                                    <div className="d-grid gap-2">
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            <div className='text-center pt-5'>
                                <Card className='p-3'>
                                    <Container>
                                        <Card.Title>Aún no tiene dispositivos</Card.Title>
                                        <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
                                            Registre su primer dispositivo:
                                            <Button onClick={() => setShowModal(true)}>Agregar dispositivo</Button>
                                        </Card.Body>
                                    </Container>
                                </Card>
                            </div>
                        )
                    }

                </div>
            )}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar un dispositivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form autoComplete='off'>
                        <FloatingLabel label='Código del dispositivo'>
                            <Form.Control type='text' placeholder='Ingrese el código del dispositivo'
                                value={newDeviceCode} // Enlaza el valor del campo con el estado
                                onChange={(e) => setNewDeviceCode(e.target.value)} // Manejador para actualizar el estado
                            ></Form.Control>
                        </FloatingLabel>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleAddDevice}>Agregar</Button> {/* Llama a la función de envío */}
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default Devices